body {
  background-color: #f9f9fb;
}

.App {
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.rbt-close-content {
  display:none !important;
}

.search-select-item {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
}

.search-select-item small {
  color: #aaaaaa;
  font-size: 14px;
}